.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
  
  .subscribe-amount {
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    font-size: 2rem;
  }
  
  .subscribe-button {

    button {
      cursor: pointer;
      margin-top: 3em;
      border-radius: 10px;
      border: 0px;
      padding: 0.5em 2em 0.5em;
      background-color: #1f5ae2;
      color: white;
      font-size: 1rem;
    }
  }

}