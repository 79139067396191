.video-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 6.25em auto auto;
  grid-row-gap: 0.3em;
  grid-column-gap: 0.3em;
  margin-bottom: 5em;
}

@media (orientation: landscape) {
  .video-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 6.25em auto auto;
    grid-row-gap: 0.3em;
    grid-column-gap: 0.3em;
    margin-bottom: 5em;
  }
}