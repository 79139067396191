.video-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1em;
  margin-right: 1em;

  .upload-title {
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 0.5em;
  }

  .upload-form {
    margin: 0 auto;
    max-width: 29em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    
    .upload-button {
      cursor: pointer;
      margin-top: 3em;
      border-radius: 10px;
      border: 0px;
      padding: 0.5em 2em 0.5em;
      background-color: #1f5ae2;
      color: white;
      font-size: 1rem;
    }
  }
}