.thumbnail {
  height: 6.25em !important; 
  width: 6.25em !important;
  cursor: pointer;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    border-radius: 10px;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }

}

