.feature-video {
  width: 20em;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  
  span {
    font-size: 0.8rem;
  }
  .feature {
    display: flex;
    flex-direction: column;
    position: relative;
    
    video {
      cursor: pointer;
      width: 100%;
      border-radius: 15px;
      object-fit: cover;
    }
  
    video::-webkit-media-controls-start-playback-button {
      display: none !important;
    }
  
    .feature-content {
      z-index: 3;
      right: 0;
      background: transparent;
      position: absolute;
  
      .feature-pay-icon {
        cursor: pointer;
        margin-right: 1em;
        margin-top: 1em;
        background-color: black;
        height: 1.5em;
        border-radius: 10px;
        padding-left: 0.35em;
        padding-right: 0.35em;
        padding-bottom: 0.2em;
  
        img {
          height: 1.8em;
          object-fit: scale-down;
        }
      }
    }
  }
  
}
