.checkout-container {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  overflow: hidden !important;
  height: -webkit-fill-available !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &__logo {
    width: 3.8em;
    padding-right: 0.5em;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 900;
    color: white;
    margin: 0;
  }

  &__sucess {
    // background-color: $success;
    margin-top: 2em;
    text-align: center;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-weight: 400 !important;
    }

    &__image {
      margin-top: 1.5em;
      max-width: 30em;
      margin-left: 5em;
      margin-right: 5em;
      img {
        border-radius: 8px;
      }
    }

    Button {
      margin-top: 1em;
      font-size: 1rem !important;
      padding: 0.8em 2.5em 0.8em;
    }
  }
}
